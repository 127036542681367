import { useStore } from "@/store/store";

export function toFixed(num: number, fixed: number) {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    const formattedNumber = num.toString().match(re);
    return formattedNumber && formattedNumber.length > 0 ? formattedNumber[0] : "0.00";
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
export const loop = async (func: () => Promise<any>) => {
    const store = useStore();
    store.state.watcher = true;
    while (store.state.watcher) {
        await func();
        await delay(3000);
    }
}