import { Farm } from "./types";

export const BINANCE_NETWORK_ID = process.env.VUE_APP_BINANCE_NETWORK_ID;
export const BINANCE_CONNECTION = {
    rpc: { [BINANCE_NETWORK_ID as string]: process.env.VUE_APP_BINANCE_RPC },
    network: process.env.VUE_APP_BINANCE_NETWORK_NAME,
};
export const DOGGGO_ADDRESS = process.env.VUE_APP_DOGGGO_ADDRESS;
export const DOGGGO_LP_MAKER_ADDRESS = process.env.VUE_APP_DOGGGO_LP_MAKER_ADDRESS;
export const DOGGGO_BNB_LP_ADDRESS = "0xedDB9F7fe824cA485cC9ae1aecB3C39F351dB62f";
export const DOGGGO_BUSD_LP_ADDRESS = "0xbf70910a8e2122c32172dfd109d87297e25f0036";
export const ABI_PATH = "abi/";
export const LIVE_FARMS: Farm[] = [
    {
        farmAddress: "0x5be48bf6b615c35ec52317f07B07f1deF1EE4369",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BNB_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBnbLP.json`,
        farmName: "DOGGGO-BNB LP",
        farmImage: "farm-imgs/dogggo-bnb.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/BNB/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x826dFA01dFf9Fc3D0da6BE8B2cFC1258C35E6CE4",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BUSD_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBusdLP.json`,
        farmName: "DOGGGO-BUSD LP",
        farmImage: "farm-imgs/dogggo-busd.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x41c3c97701cebe2a8a6a5013acfe28f6c1eb2012",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_ADDRESS!,
        stakedTokenAbiPath: `${ABI_PATH}Dogggo.json`,
        farmName: "DOGGGO",
        farmImage: "farm-imgs/dogggo.png",
        isStakedTokenLp: false,
    }
];

export const FINISHED_FARMS: Farm[] = [
    {
        farmAddress: "0x9A66Be0e60eb6748Ad9b30Bb809d18a4B714f638",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BNB_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBnbLP.json`,
        farmName: "DOGGGO-BNB LP",
        farmImage: "farm-imgs/dogggo-bnb.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/BNB/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x831c42F637de48E1449edfB3D6F65bD9815f6FCC",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BUSD_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBusdLP.json`,
        farmName: "DOGGGO-BUSD LP",
        farmImage: "farm-imgs/dogggo-busd.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x4e89fb476cc637b2413a63179d5687c742f2a6e8",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BNB_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBnbLP.json`,
        farmName: "DOGGGO-BNB LP",
        farmImage: "farm-imgs/dogggo-bnb.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/BNB/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0xF0d82f38d640342F06378dbBAd46c75c20EC1554",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BUSD_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBusdLP.json`,
        farmName: "DOGGGO-BUSD LP",
        farmImage: "farm-imgs/dogggo-busd.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0xDB7041Ff68DeD6f6877F46607714f01C15F6Cdce",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BNB_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBnbLP.json`,
        farmName: "DOGGGO-BNB LP",
        farmImage: "farm-imgs/dogggo-bnb.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/BNB/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x0aD4fFaB4c8BBbAB4962eDe8028d41df25045abe",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BUSD_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBusdLP.json`,
        farmName: "DOGGGO-BUSD LP",
        farmImage: "farm-imgs/dogggo-busd.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x6c6b6207ca69c5992cab0454b0a6ba88dd0777b5",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BNB_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBnbLP.json`,
        farmName: "DOGGGO-BNB LP",
        farmImage: "farm-imgs/dogggo-bnb.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/BNB/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x40c7543ba94988a14c0e07b166a397ab4f6a0ff3",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BUSD_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBusdLP.json`,
        farmName: "DOGGGO-BUSD LP",
        farmImage: "farm-imgs/dogggo-busd.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x4155f31cff51c2a1e281ef996c5955fc02a71c47",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BNB_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBnbLP.json`,
        farmName: "DOGGGO-BNB LP",
        farmImage: "farm-imgs/dogggo-bnb.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/BNB/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x7dfb5ca86247973ed4b967b65fe8e162a7b39cfa",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BUSD_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBusdLP.json`,
        farmName: "DOGGGO-BUSD LP",
        farmImage: "farm-imgs/dogggo-busd.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0xe4D7A3DFb14B34C142a26D59Fe91dF9416322C5B",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BNB_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBnbLP.json`,
        farmName: "DOGGGO-BNB LP",
        farmImage: "farm-imgs/dogggo-bnb.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/BNB/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0xeb7dc294f0697667a348a313bbedca95db1184c6",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_ADDRESS!,
        stakedTokenAbiPath: `${ABI_PATH}Dogggo.json`,
        farmName: "DOGGGO",
        farmImage: "farm-imgs/dogggo.png",
        isStakedTokenLp: false,
    },
    {
        farmAddress: "0x733d6ba0dec531e4e0c4839a57eeaa48a5bddad5",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BUSD_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBusdLP.json`,
        farmName: "OLD DOGGGO-BUSD LP",
        farmImage: "farm-imgs/dogggo-busd.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0x463DEDFbaf837D89f136d02d89F031B23A0E4677",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BNB_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBnbLP.json`,
        farmName: "DOGGGO-BNB LP",
        farmImage: "farm-imgs/dogggo-bnb.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/BNB/${DOGGGO_ADDRESS}`
    },
    {
        farmAddress: "0xe1319d4eb0aa25eebf1510f5bd110e7e5b567114",
        farmAbiPath: `${ABI_PATH}DogggoBnbFarm.json`,
        stakedTokenAddress: DOGGGO_BNB_LP_ADDRESS,
        stakedTokenAbiPath: `${ABI_PATH}DogggoBnbLP.json`,
        farmName: "BETA TEST DOGGGO-BNB LP",
        farmImage: "farm-imgs/dogggo-bnb.png",
        isStakedTokenLp: true,
        liquidityUrl: `https://pancakeswap.finance/add/BNB/${DOGGGO_ADDRESS}`
    }
];
const BSCSCAN = process.env.VUE_APP_BSCSCAN;
export const URLS = {
    bscscan: `${BSCSCAN}/token/${DOGGGO_ADDRESS}`,
    buy: `https://pancakeswap.finance/swap?outputCurrency=${DOGGGO_ADDRESS}`,
    chart: `https://poocoin.app/tokens/${DOGGGO_ADDRESS}`,
    buyGuide: "download/How-to-buy-Dogggo.pdf",
    twitter: "https://twitter.com/DogggoToken",
    medium: "https://dogggo.medium.com/",
    facebook: "https://www.facebook.com/dogggotoken",
    instagram: "https://instagram.com/dogggotoken/",
    reddit: "https://www.reddit.com/r/DogggoToken/",
    tiktok: "https://www.tiktok.com/@dogggo_trollcoin",
    telegram: "https://t.me/dogggotoken",
    telegramMemes: "https://t.me/dogggomeme",
    discord: "https://discord.gg/RaAFEdE6bw",
    blockExplorer: `${BSCSCAN}/block/`,
    farm: `${BSCSCAN}/address/`,
    whitepaper: "https://dogggo.medium.com/dogggopaper-deb09c3398c3"
}
