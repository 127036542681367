
import { BINANCE_CONNECTION } from "@/constants";
import { useStore } from "@/store/store";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

export default async function useNetwork() {
    const providerOptions = {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                ...BINANCE_CONNECTION,
                qrcode: true,
                pollingInterval: 3000,
            },
        },
    };

    const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions,
    });

    const store = useStore();

    const connectToNetwork = async () => {
        let provider: any = null;
        try {
            provider = await web3Modal.connect();
        } catch (e) {
            console.log("Could not get a wallet connection", e);
            return;
        }

        store.commit("setupWeb3", provider);

        provider.on("accountsChanged", async () => {
            await fetchAccountData();
        });

        provider.on("chainChanged", async () => {
            await fetchAccountData();
        });

        provider.on("networkChanged", async () => {
            await fetchAccountData();
        });

        await fetchAccountData();
    }

    const unwatchChanges = () => {
        store.state.watcher = false;
    }

    const disconnectFromNetwork = () => {
        unwatchChanges();
        web3Modal.clearCachedProvider();
        store.commit("setCurrentUser", "");
        store.commit("setChainId", 0);
        store.commit("setupWeb3", null);
    }

    const fetchAccountData = async () => {
        store.commit("setChainId", await store.state.web3.eth.getChainId());
        const accounts = await store.state.web3.eth.getAccounts();
        store.commit("setCurrentUser", accounts[0]);
    }

    if (web3Modal.cachedProvider) {
        await connectToNetwork();
    }

    return {
        connectToNetwork,
        disconnectFromNetwork
    }
}