import { useStore } from "@/store/store";
import { Farm } from "@/types";
import { computed, ref } from "vue";
import { AbiItem } from 'web3-utils';
import { toFixed } from "./utils";

export default async function useFarms(farm: Farm) {
    const loadAbi = async (abiPath: string) => {
        const res = await fetch(abiPath, {
            method: 'get'
        });
        return res.json();
    }

    const store = useStore();

    const doggoFarmAbi = await loadAbi(farm.farmAbiPath);
    const stakedTokenAbi = await loadAbi(farm.stakedTokenAbiPath);
    const dogggoFarm = new store.state.web3.eth.Contract(doggoFarmAbi as AbiItem[], farm.farmAddress);
    const stakedToken = new store.state.web3.eth.Contract(stakedTokenAbi as AbiItem[], farm.stakedTokenAddress);

    const pendingRewards = ref("0");

    const stakedTokenAmount = ref("");
    const stakedTokenBalanceWei = ref("");
    const stakedTokenBalance = computed(() => formatBalance(stakedTokenBalanceWei.value));

    const tokenToStakeAmount = ref("");
    const tokenToStakeAmountWei = computed(() => store.state.web3.utils.toWei(tokenToStakeAmount.value));
    const tokenToStakeBalance = ref("");

    const formatBalance = (balance: string, decimals = 2) => {
        return toFixed((+store.state.web3.utils.fromWei(balance)), decimals);
    }

    const getUserInfo = async (): Promise<{ 0: string, 1: string, 2: string[] }> => {
        const result = await dogggoFarm.methods.getUserInfo(store.state.currentUser).call();
        result[0] = formatBalance(result[0], 0);
        return result;
    }

    const [startBlock, finishBlock, rewardPerBlock, poolTokenAmount, allStakedAmount, stakedTokenAllowanceToRef, userInfoToRef] = await Promise.all([
        dogggoFarm.methods.startBlock().call(),
        dogggoFarm.methods.finishBlock().call(),
        dogggoFarm.methods.rewardPerBlock().call(),
        dogggoFarm.methods.poolTokenAmount().call(),
        dogggoFarm.methods.allStakedAmount().call(),
        stakedToken.methods.allowance(store.state.currentUser, farm.farmAddress).call(),
        getUserInfo()
    ]);

    const userInfo = ref(userInfoToRef);
    const stakedTokenAllowance = ref(stakedTokenAllowanceToRef);

    const getRewards = async () => {
        if (store.state.currentUser) {
            const [pendingRewardsWei, tokenToStakeBalanceWei] = await Promise.all([dogggoFarm.methods.pendingReward(store.state.currentUser).call(), stakedToken.methods.balanceOf(store.state.currentUser).call()]);
            pendingRewards.value = formatBalance(pendingRewardsWei, 0);
            tokenToStakeBalance.value = formatBalance(tokenToStakeBalanceWei);
        }
    }

    const getDistributedTokensPerc = async () => {
        const currentBlock = await store.state.web3.eth.getBlockNumber();

        if (currentBlock < startBlock) {
            return 0;
        }
        if (currentBlock < finishBlock) {
            const blocksPassed = currentBlock - (+startBlock);
            const distributedTokens = toBN(rewardPerBlock).mul(toBN(blocksPassed));
            return distributedTokens.mul(toBN(100)).div(toBN(poolTokenAmount)).toString();
        } else {
            return 100;
        }
    }

    const approve = async () => {
        const amount = store.state.web3.utils.toWei("11579208923731619542357098500868790785326998466564056403945", "ether");
        await stakedToken.methods.approve(farm.farmAddress, amount).send({ from: store.state.currentUser });
        stakedTokenAllowance.value = await stakedToken.methods.allowance(store.state.currentUser, farm.farmAddress).call();
    }

    const stake = async (amount: string) => {
        await dogggoFarm.methods.stakeTokens(amount).send({ from: store.state.currentUser });
    }

    const unstake = async (amount: string, poolId: string) => {
        await dogggoFarm.methods.withdrawStake(amount, poolId).send({ from: store.state.currentUser });
    }

    const harvest = async () => {
        await dogggoFarm.methods.stakeTokens("0").send({ from: store.state.currentUser });
    }

    const unstakeFromClosedPool = async () => {
        await dogggoFarm.methods.withdrawPoolRemainder().send({ from: store.state.currentUser });
    }

    const getPoolIdInfo = async (poolId: string): Promise<{ 0: string, 1: string, 2: string, 3: string }> => {
        return await dogggoFarm.methods.getUserPoolInfo(poolId).call();
    }

    const getApy = async () => {
        // const APR = dogggoprice * rewardPerBlock * 10512000 / dogggobnbprice / pool.totalSupply (of f-tokens); 10512000 blocks in a year
        const aprdogggosingle = toBN(rewardPerBlock).mul(toBN("10512000")).div(toBN(allStakedAmount)).mul(toBN("100"));
        return toFixed(+aprdogggosingle.toString(), 2);
    }

    const setMaxTokenToStake = () => {
        tokenToStakeAmount.value = tokenToStakeBalance.value;

    }

    const setMaxStakedToken = () => {
        stakedTokenAmount.value = userInfo.value[0];
    }

    const toBN = (value: string | number) => {
        return store.state.web3.utils.toBN(value);
    }

    const toWei = (value: string | number) => {
        const number = toBN(value);
        return store.state.web3.utils.toWei(number);
    }

    return { approve, stake, unstake, harvest, unstakeFromClosedPool, getUserInfo, getRewards, pendingRewards, startBlock, finishBlock, stakedTokenAllowance, getApy, tokenToStakeBalance, setMaxTokenToStake, tokenToStakeAmount, getDistributedTokensPerc, stakedTokenAmount, setMaxStakedToken, stakedTokenBalance, getPoolIdInfo, userInfo, toBN, toWei, rewardPerBlock, allStakedAmount, tokenToStakeAmountWei }
}