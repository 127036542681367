
import { ref, defineComponent, computed } from "vue";
import useNetwork from "../composables/useNetwork";
import { BINANCE_NETWORK_ID, URLS } from "../constants";
import Farms from "./Farms.vue";
import { useStore } from "../store/store";

export default defineComponent({
  name: "Content",
  components: {
    Farms
  },
  setup: async () => {
    const isHttps = ref(true);
    const store = useStore();

    if (location.protocol !== "https:") {
      isHttps.value = process.env.VUE_APP_FORCE_HTTPS === "true";
    }

    const { connectToNetwork, disconnectFromNetwork } = await useNetwork();

    return {
      isHttps,
      chainId: computed(() => store.state.chainId),
      selectedAccount: computed(() => store.state.currentUser),
      connectToNetwork,
      disconnectFromNetwork,
      BINANCE_NETWORK_ID,
      URLS,
    };
  },
});
