import { DOGGGO_ADDRESS } from "@/constants";
import { useStore } from "@/store/store";
import { Farm } from "@/types"
import { AbiItem } from 'web3-utils';

export default function usePancakeApi(farm: Farm) {
    const getRewardTokenPrice = async () => {
        return await getTokenPrice(DOGGGO_ADDRESS!);
    }

    const getStakedTokenPrice = async () => {
        if (farm.isStakedTokenLp) {
            return await getStakedTokenPriceForLp();
        }
        // const [rewardTokenPrice, stakedTokenPrice] = await Promise.all([
        //     getRewardTokenPrice(),
        //     getStakedTokenPrice(),
        // ]);

        return await getTokenPrice(farm.stakedTokenAddress);
    }

    const getTokenPrice = async (address: string) => {
        const res = await fetch(`https://api.dogggo.io/v1/pancakePrice/${address}`);
        const data = await res.json();
        return data;
    }

    const getStakedTokenPriceForLp = async () => {
        const loadAbi = async (abiPath: string) => {
            const res = await fetch(abiPath, {
                method: 'get'
            });
            return res.json();
        }

        const store = useStore();

        const toBN = (value: string | number) => {
            return store.state.web3.utils.toBN(value);
        }

        const stakedTokenAbi = await loadAbi(farm.stakedTokenAbiPath);
        const stakedToken = new store.state.web3.eth.Contract(stakedTokenAbi as AbiItem[], farm.stakedTokenAddress);
        const [totalSupply, reserves, token0, token1] = await Promise.all([
            stakedToken.methods.totalSupply().call(),
            stakedToken.methods.getReserves().call(),
            stakedToken.methods.token0().call(),
            stakedToken.methods.token1().call()
        ]);

        const [price0, price1] = await Promise.all([
            getTokenPrice(token0),
            getTokenPrice(token1)
        ]);

        const poolTotalPrice = (toBN(Math.round(price1)).mul(toBN(reserves[1])).div(toBN(10 ** 18)).mul(toBN(2))).toNumber();
        const totalLPSupply = (toBN(totalSupply).div(toBN(10 ** 18))).toNumber();
        return poolTotalPrice / totalLPSupply;
    }

    return { getRewardTokenPrice, getStakedTokenPrice };
}