import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import Web3 from 'web3'

export interface State {
    web3: Web3,
    currentUser: string,
    chainId: number,
    watcher: boolean
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        web3: new Web3(),
        currentUser: "",
        chainId: 0,
        watcher: true
    },
    mutations: {
        setupWeb3(state: State, provider: any) {
            state.web3 = new Web3(provider);
        },
        setCurrentUser(state: State, user: string) {
            state.currentUser = user;
        },
        setChainId(state: State, id: number) {
            state.chainId = id;
        }
    }
})

export function useStore() {
    return baseUseStore(key);
}